import { createSlice } from '@reduxjs/toolkit'
import reducerName from './name'

// Store
export const survey = createSlice({
  name: reducerName,
  initialState: {
    responses: [],
    steps: [],
    pdf: null,
    result: null
  },
  reducers: {
    setResponses: (state, { payload }) => {
      state.responses = payload.answers
    },
    setNewResponse: (state, { payload }) => {
      const currentResponse = state.responses.findIndex(answer => answer.SK.endsWith(`_${payload.question.id}`))
      if (currentResponse !== -1) { state.responses[currentResponse] = payload } else { state.responses.push(payload) }
    },
    resetResponse: (state) => {
      state.responses = []
    },
    setSteps: (state, { payload }) => {
      state.steps = payload
    },
    setResult: (state, { payload }) => {
      state.result = payload
    }
  }
})

// Reducer Actions - reducer actions are defined inside reducers prop above
export const {
  setResponses,
  setNewResponse,
  setSteps,
  setResult,
  resetResponse
} = survey.actions

// Reducer
export default survey.reducer
