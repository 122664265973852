import React, { useMemo, useState } from 'react'
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined'
import { Grid, Paper, Typography, Tooltip, Skeleton } from '@mui/material'
import { useDispatch, useSelector } from 'react-redux'
import { useStyles } from './style'
import { userSlice } from '../../reducers'
import { ReSwitchButton } from '..'
import { userPathwayMapping } from './pathwayMapping'
import YourTrackModal from '../your-track-modal'
import { toggleToolpathwayOrQuestionnaire } from '../../reducers/user/slice'

const YourTrackCard = () => {
  const classes = useStyles()
  const [modalOpen, setModalOpen] = useState(false)
  const dispatch = useDispatch()

  const user = useSelector(userSlice.selectors.selectUser)
  const showing = useSelector(userSlice.selectors.selectShowToolpathOrQuestionnaire)
  const dashboardData = useSelector(userSlice.selectors.dashboardData)
  const pathwayType = user?.pathwayType?.name || 'General'

  const toogleToolPath = () => {
    dispatch(toggleToolpathwayOrQuestionnaire())
  }

  const canShowSwitch = useMemo(() => {
    const levelKey = dashboardData?.evaluationReportResult?.modelResult?.levelKey || dashboardData?.indicatorReportResult?.modelResult?.levelKey
    return user && levelKey && !['breakdown', 'fragmentation'].includes(levelKey)
  }, [dashboardData, user])

  const resilienceLevel = useMemo(() => {
    let result = ' - '
    if (dashboardData?.evaluationReportResult) {
      result = dashboardData?.evaluationReportResult?.modelResult?.level
    } else if (dashboardData?.indicatorReportResult) {
      result = dashboardData?.indicatorReportResult?.modelResult?.level
    }
    return result
  }, [dashboardData?.indicatorReportResult, dashboardData?.evaluationReportResult])

  if (!user?.pathwayType) return <Skeleton height={320} variant='rectangular' />
  return (
    <Paper elevation={2} className={classes.container}>
      <Grid container direction='column' alignItems='center' justifyContent='space-between' flexWrap='nowrap' className={classes.content}>
        <Grid item>
          <img width={140} height={140} src={`/img/pathway/${showing === 'toolpathway' ? userPathwayMapping[pathwayType].img : 'Questionnaire.svg'}`} alt='your-track' />
        </Grid>
        {showing === 'toolpathway'
          ? (
            <Grid container direction='row' item alignItems='center' justifyContent='center'>
              <YourTrackModal open={modalOpen} handleClose={() => setModalOpen(false)} />
              <Typography variant='h6' className={classes.toolpathwayTitle}>{userPathwayMapping[pathwayType].title}</Typography>
              <Tooltip title={userPathwayMapping[pathwayType].shortDescription}>
                <InfoOutlinedIcon className={classes.informationIcon} />
              </Tooltip>
            </Grid>)
          : (
            <Grid container direction='column' item alignItems='center' justifyContent='center'>
              <Typography variant='body2' className={classes.resilienceLevelTitle}>Resilience level </Typography>
              <Typography variant='h6' className={classes.toolpathwayTitle}>{resilienceLevel}</Typography>
            </Grid>)}
        {showing === 'toolpathway' &&
          <Grid item>
            <Typography onClick={() => setModalOpen(true)} className={classes.readMore}>Read more</Typography>
          </Grid>}
        <Grid item marginTop={1}>
          {canShowSwitch &&
            <ReSwitchButton
              leftOption='Tracking Pathway' rightOption='Resilience Level Pathway'
              state={showing === 'toolpathway' ? 'left' : 'right'}
              onClick={toogleToolPath}
            />}
        </Grid>
      </Grid>
    </Paper>
  )
}

export default YourTrackCard
