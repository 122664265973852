import * as actions from './slice'
import * as userActions from '../user/slice'

export const saveAnswer = {
  eventName: 'saveAnswer',
  onSuccess: [{ redux: true, action: actions.setNewResponse }]
}

export const getUserAnswers = {
  eventName: 'Get User Indicator Answers',
  onSuccess: [{ redux: true, action: actions.setResponses }]
}

export const getSteps = {
  eventName: 'Get Indicator Steps',
  onSuccess: [{ redux: true, action: actions.setSteps }]
}

export const calculate = {
  eventName: 'Calculate Indicator Answer',
  onSuccess: [{ redux: true, action: actions.setResult }, { redux: true, action: userActions.setIndicatorReportResult }, { redux: true, action: actions.resetResponses }]
}
