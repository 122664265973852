import * as actions from './slice'
import * as userActions from '../user/slice'

export const saveAnswer = {
  eventName: 'saveAnswer',
  onSuccess: [{ redux: true, action: actions.setNewResponse }]
}

export const getUserAnswers = {
  eventName: 'getUserAnswers',
  onSuccess: [{ redux: true, action: actions.setResponses }]
}

export const getSteps = {
  eventName: 'getByStep',
  onSuccess: [{ redux: true, action: actions.setSteps }]
}

export const calculate = {
  eventName: 'calculate',
  onSuccess: [{ redux: true, action: actions.setResult }, { redux: true, action: userActions.setEvaluationReportResult }, { redux: true, action: actions.resetResponse }]
}
